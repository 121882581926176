export const productsTypeData = [
  {
    id: 'styledMini',
    imageSrc: require('../Images/products/styledMini/black-min.jpg'),
    type: 'Стильний Міні',
    price: 2640,
    todayPrice: 1848,
    discount: 30,
    aboutTitle:'Вкорочений пуффер з резинкою знизу для регулювання довжини, ширини та фіксації на талії для комфорту і стилю.',
    aboutText: (<div><p>- Наповнювач: аеропух; </p>
    <p>- Матеріал: матова плащівка Gloria стійка до вологи, бруду та затирання; </p>
    <p>- Підкладка: гладенька та міцна саржа; </p>
    <p>- Висока горловина стійка яка відповідає трендам, захищає від холоду та додає комфорту; </p>
    <p>- М'які та трішки подовжені манжети.</p></div>),
    colors: [
      '#030911',
      '#ea0070',
      '#d6bab9',
      '#89f5ff',
      '#dfc7f9',
      '#3e9898',
      '#eaeef9',
      '#edc003',
    ],
    colorsImg: [
      {
        id: 'smBezevy',
        img: require('../Images/products/styledMini/bezevy-min.jpg'),
        colorName: 'Бежевий',
      },
      {
        id: 'smBiryzovy',
        img: require('../Images/products/styledMini/biryzovy-min.jpg'),
        colorName: 'Бірюзовий',
      },
      {
        id: 'smBlack',
        img: require('../Images/products/styledMini/black-min.jpg'),
        colorName: 'Чорний',
      },
      {
        id: 'smFiolet',
        img: require('../Images/products/styledMini/fiolet-min.jpg'),
        colorName: 'Фіолетовий',
      },
      {
        id: 'smGreen',
        img: require('../Images/products/styledMini/green-min.jpg'),
        colorName: 'Зелений',
      },
      {
        id: 'smPink',
        img: require('../Images/products/styledMini/pink-min.jpg'),
        colorName: 'Рожевий',
      },
      {
        id: 'smWhite',
        img: require('../Images/products/styledMini/white-min.jpg'),
        colorName: 'Білий',
      },
      {
        id: 'smYellow',
        img: require('../Images/products/styledMini/yellow-min.jpg'),
        colorName: 'Жовтий',
      },
    ],
    sizes:["42-46/55","48-52/57"],
    sizeTable:(<div> <table className='productSize-table'>
    <thead>
      <tr>
        <th>Заміри</th>
        <th>Розмір 42-46</th>
        <th>Розмір 48-52</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>Ваш ОГ, см</td>
        <td>88-104</td>
        <td>102-120</td>
      </tr>
      <tr>
        <td>Напів ОГ виробу, см</td>
        <td>62</td>
        <td>66</td>
      </tr>
      <tr>
        <td>Довжина, см</td>
        <td>55</td>
        <td>57</td>
      </tr>
      <tr>
        <td>Рукав, см</td>
        <td>74</td>
        <td>75</td>
      </tr>
      <tr>
        <td>Манжет, см</td>
        <td>9</td>
        <td>9</td>
      </tr>
    </tbody>
  </table></div>),
  },
  {
    id: 'practicalMidi',
    imageSrc: require('../Images/products/practicalMidi/fiolet.jpg'),
    type: 'Практичний Мід',
    price: 2828,
    todayPrice: 1979,
    discount: 30,
    aboutTitle:'Демісезонний пуффер середньої довжини з двома кишенями на застібках, подовженими трикотажними манжетами та високою горловиною стійка',
    aboutText: (<div><p>- Наповнювач: аеропух; </p>
    <p>- Матеріал: матова плащівка Gloria стійка до бруду, вологи та затирання;</p>
    <p>- Підкладка: міцна та гладенька саржа;</p>
    <p>- Висока горловина стійка відповідає трендам, захищає від холоду та додає комфорту;</p>
    <p>- М'які та трішки подовжені манжети.</p></div>),
    
     
    
   
    colors: ['#a78ef3', '#f6e3e9', '#9bf8ff', '#1b1517', '#58b4a9', '#dfe7fe'],
    colorsImg: [
      {
        id: 'pmBezevy',
        img: require('../Images/products/practicalMidi/bezevy.jpg'),
        colorName: 'Бежевий',
      },
      {
        id: 'pmBiryzovy',
        img: require('../Images/products/practicalMidi/biryzovy.jpg'),
        colorName: 'Бірюзовий',
      },
      {
        id: 'pmBlack',
        img: require('../Images/products/practicalMidi/black.jpg'),
        colorName: 'Чорний',
      },
      {
        id: 'pmFiolet',
        img: require('../Images/products/practicalMidi/fiolet.jpg'),
        colorName: 'Фіолетовий',
      },
      {
        id: 'pmGreen',
        img: require('../Images/products/practicalMidi/green.jpg'),
        colorName: 'Зелений',
      },
      {
        id: 'pmWhite',
        img: require('../Images/products/practicalMidi/white.jpg'),
        colorName: 'Білий',
      },
    ],
    sizes:["42-46/73","48-50/74"],
    sizeTable:(<div><table className='productSize-table'>
    <thead>
      <tr>
        <th>Заміри</th>
        <th>Розмір 42-46</th>
        <th>Розмір 48-50</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>Ваш ОГ, см</td>
        <td>88-106</td>
        <td>104-122</td>
      </tr>
      <tr>
        <td>Напів ОГ виробу, см</td>
        <td>65</td>
        <td>69</td>
      </tr>
      <tr>
        <td>Довжина, см</td>
        <td>73</td>
        <td>74</td>
      </tr>
      <tr>
        <td>Рукав, см</td>
        <td>78</td>
        <td>78</td>
      </tr>
      <tr>
        <td>Манжет, см</td>
        <td>9</td>
        <td>9</td>
      </tr>
    </tbody>
  </table></div>),
  },
  {
    id: 'warmMaxi',
    imageSrc: require('../Images/products/warmMaxi/pink.jpeg'),
    type: 'Теплий Максі',
    price: 3989,
    todayPrice: 2792,
    discount: 30,
    aboutTitle:'Стильний, якісний та комфортний великий пуффер в двох варіантах довжини',
    aboutText:(<div><p>- 120 см та 100 см (обирайте довжину при виборі розміру);</p>
    <p>- Наповнювач: набивний аеропух;</p>
    <p>- Плащівка матова та стійка до бруду і вологи ;</p>
    <p>- Підкладка міцна та гладенька саржа;</p>
    <p>- Великий відстібний капюшон що заховає від неочікуваного дощу мінливої погоди🥰;</p>
    <p>- М'які та теплі манжети завдяки яким можна регулювати довжину рукава.</p></div>),
    colors: [
      '#ee2164',
      '#c1ae9f',
      '#5accd7',
      '#03030f',
      '#baa3f1',
      '#104e59',
      '#a2cb2d',
      '#c2d1ce',
      '#faf9ff',
      '#fcf823',
    ],
    colorsImg: [
      {
        id: 'wmBezevy',
        img: require('../Images/products/warmMaxi/bezevy.jpeg'),
        colorName: 'Бежевий',
      },
      {
        id: 'wmBiryzovy',
        img: require('../Images/products/warmMaxi/biryzovy.jpeg'),
        colorName: 'Бірюзовий',
      },
      {
        id: 'wmBlack',
        img: require('../Images/products/warmMaxi/black.jpeg'),
        colorName: 'Чорний',
      },
      {
        id: 'wmFiolet',
        img: require('../Images/products/warmMaxi/fiolet.jpeg'),
        colorName: 'Фіолетовий',
      },
      {
        id: 'wmGreen',
        img: require('../Images/products/warmMaxi/green.jpeg'),
        colorName: 'Зелений',
      },
      {
        id: 'wmLaym',
        img: require('../Images/products/warmMaxi/laym.jpeg'),
        colorName: 'Лаймовий',
      },
      {
        id: 'wmMint',
        img: require('../Images/products/warmMaxi/mint.jpeg'),
        colorName: 'М`ятний',
      },
      {
        id: 'wmPink',
        img: require('../Images/products/warmMaxi/pink.jpeg'),
        colorName: 'Рожевий',
      },
      {
        id: 'wmWhite',
        img: require('../Images/products/warmMaxi/white.jpeg'),
        colorName: 'Білий',
      },
      {
        id: 'wmYellow',
        img: require('../Images/products/warmMaxi/yellow.jpeg'),
        colorName: 'Жовтий',
      },
    ],
    sizes:["42-46/100","42-46/120","48-50/100","48-50/120","52-54/100","52-54/120"],
    sizeTable:(<div> <table className='productSize-table'>
    <thead>
      <tr>
        <th>Розмір</th>
        <th>Обхват грудей, см</th>
        <th>Довжина, см</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>42-46</td>
        <td>88-108</td>
        <td>100\120</td>
      </tr>
      <tr>
        <td>48-50</td>
        <td>106-116</td>
        <td>100\120</td>
      </tr>
      <tr>
        <td>52-54</td>
        <td>114-124</td>
        <td>100\120</td>
      </tr>
    </tbody>
  </table>
  <p>*  Розмір 52-54 доступний під замовлення з доплатою 200 грн, термін виробництва 5 робочих днів, потім доставка 1-3 дні. Також можете залишити заявку і уточнити наявність розміру.</p></div>),
  },

  {
    id: 'ZatyshniyMid',
    imageSrc: require('../Images/products/ZatyshniyMid/ZatyshniyMidGreen.jpg'),
    type: 'Затишний Мід+',
    price: 3998,
    todayPrice: 2799,
    discount: 30,
    aboutTitle:'Демісезонний пуффер середньої довжини з двома кишенями на застібках, теплим відстібним капюшоном та зручними фіксаторами знизу',
    aboutText: (<div><p>- Наповнювач: щільний зимній  аеропух; </p>
    <p>- Матеріал: матова плащівка Gloria стійка до бруду, вологи та затирання;</p>
    <p>- Підкладка: міцна та гладенька саржа;</p>
    <p>- Великий відстібний капюшон що заховає від неочікуваної мінливої погоди;;</p>
    <p>- Затяжки на фіксаторах знизу куртки, дозволить щільніше заховатися від холодного повітря;</p></div>),
    
     
    
   
    colors: ['#000000', '#ADD8E6', '#FFFF00', '#800080', '#FFFDD0', '#E30B5C','#008000','#F5F5DC','#D0A98F','#7B3F00'],
    colorsImg: [
      {
        id: 'zmGreen',
        img: require('../Images/products/ZatyshniyMid/ZatyshniyMidGreen.jpg'),
        colorName: 'Зелений',
      },
    { id: 'zmBlack',
        img: require('../Images/products/ZatyshniyMid/ZatyshniyMidBlack.jpg'),
        colorName: 'Чорний',
      },
      {
        id: 'zmWhiteBlue',
        img: require('../Images/products/ZatyshniyMid/ZatyshniyMidWhiteBlue.jpg'),
        colorName: 'Блакитний',
      },
      {
        id: 'zmYellow',
        img: require('../Images/products/ZatyshniyMid/ZatyshniyMidYellow.jpg'),
        colorName: 'Жовтий',
      },
      {
        id: 'zmFiolet',
        img: require('../Images/products/ZatyshniyMid/ZatyshniyMidFiolet.jpg'),
        colorName: 'Фіолетовий',
      },
      {
        id: 'zmMilk',
        img: require('../Images/products/ZatyshniyMid/ZatyshniyMidMilk.jpg'),
        colorName: 'Молочний',
      },
      {
        id: 'zmMalinovy',
        img: require('../Images/products/ZatyshniyMid/ZatyshniyMidMalinovy.jpg'),
        colorName: 'Малиновий',
      },
     
      {
        id: 'zmBezevy',
        img: require('../Images/products/ZatyshniyMid/ZatyshniyMidBezevy.jpg'),
        colorName: 'Бежевий',
      },
      {
        id: 'zmKapuchino',
        img: require('../Images/products/ZatyshniyMid/ZatyshniyMidKapuchino.jpg'),
        colorName: 'Капучино',
      },
      {
        id: 'zmChokolet',
        img: require('../Images/products/ZatyshniyMid/ZatyshniyMidChokolet.jpg'),
        colorName: 'Шоколад',
      },
      {
        id: 'zmBlack',
        img: require('../Images/products/ZatyshniyMid/ZatyshniyMidBlack1.jpg'),
        colorName: 'Чорний',
      },
      {
        id: 'zmWhiteBlue',
        img: require('../Images/products/ZatyshniyMid/ZatyshniyMidWhiteBlue1.jpg'),
        colorName: 'Блакитний',
      },
      {
        id: 'zmYellow',
        img: require('../Images/products/ZatyshniyMid/ZatyshniyMidYellow1.jpg'),
        colorName: 'Жовтий',
      },
      {
        id: 'zmFiolet',
        img: require('../Images/products/ZatyshniyMid/ZatyshniyMidFiolet1.jpg'),
        colorName: 'Фіолетовий',
      },
      {
        id: 'zmMilk',
        img: require('../Images/products/ZatyshniyMid/ZatyshniyMidMilk1.jpg'),
        colorName: 'Молочний',
      },
      {
        id: 'zmMalinovy',
        img: require('../Images/products/ZatyshniyMid/ZatyshniyMidMalinovy1.jpg'),
        colorName: 'Малиновий',
      },
      {
        id: 'zmGreen',
        img: require('../Images/products/ZatyshniyMid/ZatyshniyMidGreen1.jpg'),
        colorName: 'Зелений',
      },
      {
        id: 'zmBezevy',
        img: require('../Images/products/ZatyshniyMid/ZatyshniyMidBezevy1.jpg'),
        colorName: 'Бежевий',
      },
      {
        id: 'zmKapuchino',
        img: require('../Images/products/ZatyshniyMid/ZatyshniyMidKapuchino1.jpg'),
        colorName: 'Капучино',
      },
      {
        id: 'zmChokolet',
        img: require('../Images/products/ZatyshniyMid/ZatyshniyMidChokolet1.jpg'),
        colorName: 'Шоколад',
      },
    
    ],
    sizes:["42-46","48-50","52-54"],
    sizeTable:(<div><table className='productSize-table'>
    <thead>
      <tr>
        <th>Заміри</th>
        <th>Розмір 42-46</th>
        <th>Розмір 48-50</th>
        <th>Розмір 48-50</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>Ваш ОГ, см</td>
        <td>88-106</td>
        <td>104-122</td>
        <td>115-140</td>
      </tr>
    
      <tr>
        <td>Довжина попереду, см</td>
        <td>62</td>
        <td>62</td>
        <td>62</td>
      </tr>
      <tr>
        <td>Довжина позаду, см</td>
        <td>77</td>
        <td>77</td>
        <td>77</td>
      </tr>
      <tr>
        <td>Рукав, см</td>
        <td>75</td>
        <td>75</td>
        <td>75</td>
      </tr>
     
    </tbody>
  </table>
  <p>* Розмір 52-54 доступний під замовлення під Ваші параметри з доплатою 200 грн, термін виробництва 5 робочих днів, потім доставка 1-3 дні. Також можете залишити заявку і уточнити наявність розміру.</p>
  </div>),
  },


];
